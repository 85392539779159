import React from "react";
import intro from "../images/intro.jpeg";
import svm from "../images/SVM.jpeg";

function Blog(){
    return(
        // <!-- Creating Blog Layout in HTML -->
        <section className="blog" id="blog">
            <div className="heading white">
                <h2>Blog</h2>
                <p>What I write</p>
            </div>
            <div className="content">
                <a href="https://blog.saurabhmishra.com.np/machine-learning-introduction" target="_blank" rel="noreferrer">
                    <div className="blogBx">
                            <img src={intro} alt="blog pic" />
                            <h3>introduction to Machine Learning</h3>
                            <p>Machine learning enables a machine to automatically learn from data, improve performance from experiences, and predict things without being explicitly programmed.</p>
                    </div>
                </a>

                <a href="https://blog.saurabhmishra.com.np/support-vector-machines" target="_blank" rel="noreferrer">
                    <div className="blogBx">
                        <img src={svm} alt="blog pic" />
                        <h3>Support Vector Machine</h3>
                        <p>The objective of the support vector machine algorithm is to find a hyperplane in an N-dimensional space (N — the number of features) that distinctly classifies the data points.</p>
                    </div>
                </a>
            </div>
            <div className="blogfooter">
                <a href="https://blog.saurabhmishra.com.np" target="_blank" rel="noreferrer">See More</a>
            </div>
        </section>
    );
}

export default Blog;