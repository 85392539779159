import React from "react";

function Footer(){
    return(
        // <!-- Creating Footer Layout in HTML -->
        <footer className="copyright">
            <p>{new Date().getFullYear()} &copy; <a href="https://www.saurabhmishra.com.np">Saurabh</a><br />All rights reserved.</p>
        </footer>
    );
}

export default Footer;