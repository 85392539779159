import React from "react";

function Project(){
    return(
        // <!-- Creating Projects Layout in HTML -->
        <section className="projects" id="projects">
            <div className="heading white">
                <h2>Projects</h2>
                {/* <!-- <p>projects I Offer to my Clients</p> --> */}
            </div>
            <div className="content">
                <a href="https://projects.saurabhmishra.com.np/category/python/" target="_blank" rel="noreferrer">
                    <div className="projectsBx">
                        {/* <i className="fa fa-code fa-4x"></i> */}
                        <i className="fa fa-brands fa-python fa-4x"></i>
                        <h3>Python</h3> 
                        <p>All my python works can be seen here.</p>
                    </div>
                </a>
                <a href="https://projects.saurabhmishra.com.np/category/dashboards/" target="_blank" rel="noreferrer">
                    <div className="projectsBx">
                        <i className="fa fa-chart-simple fa-4x"></i>
                        <h3>BI Projects</h3>
                        <p>Here, you can find the dashboards made using BI tools.</p>
                    </div>
                </a>
                <a href="https://projects.saurabhmishra.com.np/category/android/" target="_blank" rel="noreferrer">
                    <div className="projectsBx">
                        <i className="fa fa-brain fa-4x"></i>
                        <h3>Machine Learning</h3>
                        <p>Some projects done by me in Machine Learning.</p>
                    </div>
                </a>
                <a href="https://projects.saurabhmishra.com.np/category/big-data/" target="_blank" rel="noreferrer">
                    <div className="projectsBx">
                        <i className="fa fa-database fa-4x"></i>
                        <h3>Big Data Projects</h3>
                        <p>This link follows to big data and Hadoop projects.</p>
                    </div>
                </a>
            </div>
        </section> 
    );
}

export default Project;