import React from "react";
import { toggleMenu } from "./ext";

function Header(){
    return(
        // <!-- Creating Menu Tabs & Logo at Top Using HTML -->
        <header>
            <a href="." className="logo"><h1>SM</h1></a>
            <div className="toggle" onClick={toggleMenu}></div>
            <ul className="menu">
                <li><a href="#home" onClick={toggleMenu}>Home</a></li>
                <li><a href="#about" onClick={toggleMenu}>About</a></li>
                <li><a href="#projects" onClick={toggleMenu}>Projects</a></li>
                <li><a href="#blog" onClick={toggleMenu}>Blog</a></li>
                <li><a href="#contact" onClick={toggleMenu}>Contact</a></li>
            </ul>
        </header>
    );
}

export default Header;