import React from "react";
import image from "../images/myPic1.jpg";

function Banner(){
    return(
        // <!-- Creating Banner Layout in HTML -->
        <section className="banner" id="home">
            <div className="textBx">
                <h2>Hello, I am<br /> <span>Saurabh Mishra</span></h2>
                <h3>I'm a Data Scientist.</h3>
                <a href="#about" className="btn">About Me</a>
            </div>
            <div className="w50">
                <img src={image} alt="" className="proPic1" />
            </div>
        </section>   
    );
}

export default Banner;