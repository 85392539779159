import {db} from "../Firebase";
import { ref, set } from "firebase/database";
import { uid } from "uid";
import React, {useState} from "react";
import icon from "../images/chatbox-icon.svg";
var natural = require('natural');
var tf = require('@tensorflow/tfjs');
var intents_json = require('../res/intents/intents.json');


const writeToDatabase = (msg) => {
    const uuid = uid();
    set(ref(db, `messages/${uuid}`), {
        msg
    });
}

const classes = ['goodbye', 'greeting', 'identity', 'portfolio', 'services', 'thanks'];
const words = ["'s", ',', 'about', 'am', 'anyone', 'are', 'awesome', 'be', 'by', 'bye', 'can', 'catalogue', 'chatting', 'could', 'day', 'do', 'doings', 'done', 'example', 'for', 'give', 'good', 'goodbye', 'hello', 'help', 'helpful', 'helping', 'hey', 'hi', 'hola', 'how', 'i', 'introduce', 'introduction', 'is', 'later', 'me', 'next', 'nice', 'of', 'offered', 'portfolio', 'project', 'provide', 'see', 'service', 'show', 'some', 'speaking', 'support', 'tell', 'thank', 'thanks', 'that', 'there', 'till', 'time', 'to', 'what', 'where', 'who', 'work', 'you', 'your', 'yourself'];

const model = tf.loadLayersModel(process.env.PUBLIC_URL + 'model/model.json'); 

function CleanUpSentence(text1){
    const tokenizer = new natural.WordTokenizer();
    const text2 = tokenizer.tokenize(text1);
    const stemmer = natural.PorterStemmer;
    for(let i=0; i<text2.length; i++){
        text2[i] = stemmer.stem(text2[i]);
    }
    return text2;
}


// CREATE A BAG OF WORDS
function BOW(ssentence, words){
    // tokenize the sentence
    const sentence_words = CleanUpSentence(ssentence);
    // bag of words - matrix of N words, vocabulary matrix
    const bag = new Array(words.length).fill(0);
    for(let i=0; i<sentence_words.length; i++){
        for(let j=0; j<words.length; j++){
            if (words[j] === sentence_words[i]) {
                bag[j] = 1;
            }
        }
    }
    return bag;
}


async function predict_class(sentence, model){
    //filter out predictions below a threshold
    const pred = BOW(sentence, words);
    const res = async () => model.then((res) => res.predict(tf.tensor2d(pred, [1, 65]))).then((res) => res.array()).then(arr => arr[0]);
    const return_list = await res().then(arr => {
        const ERROR_THRESHOLD = 0.25;
        const result = [];
        for(let i=0; i<arr.length; i++){
            if (arr[i] > ERROR_THRESHOLD){
                result.push([i, arr[i]]);
            }
        }
        result.sort((a, b) => b[1]-a[1]);

        const return_list = [];
        for(let i=0; i<result.length; i++){
            return_list.push({"intent": classes[result[i][0]], "probability": result[i][1].toString()});
        }
        return return_list;
    });
    return return_list;
}

function getResponse(ints, intents_json){
    const tag = ints.then(arr=>arr[0]).then(item => item.intent);
    const listOfIntents = intents_json.intents;
    const result = tag.then(tag=>{
        let result = "";
        for(let i=0; i<listOfIntents.length; i++){
            if(listOfIntents[i].tag === tag){
                const intentsList = listOfIntents[i].responses;
                result = intentsList[Math.floor(Math.random()*intentsList.length)];
                break;
            }
        }
        return result;
    });
    return result;
}

function chatbotResponse(msg){
    const ints = predict_class(msg, model);
    const response = getResponse(ints, intents_json);
    return response;
}


function ChatBot(){

    const messageBox = [];

    const [toggleClass, setToggleClass] = useState(false);

    function handleInput(){
        const textField = document.querySelector('.chatInput');

        textField.addEventListener("keyup", ({key}) => {
            if (key === "Enter") {
                sendMessage();
            }
        });
    }
    
    function sendMessage(){
        const textField = document.querySelector('.chatInput');
        const text1 = textField.value;


        if (text1 === "") {
            return;
        }

        writeToDatabase(text1);
        
        const text2 = chatbotResponse(text1).then(msg => {
            messageBox.push({ name: "User", message: text1 });
            messageBox.push({ name: "Sam", message: msg });
            return messageBox;
        });
        
        updateChatText(text2);
        textField.value = '';
    }

    function updateChatText(msgList){
        msgList.then(messages => {
            let html = '';
            messages.slice().reverse().forEach((item, index) => {
                if (item.name === "Sam")
                {
                    html += '<div class="messages__item messages__item--operator">' + item.message + '</div>'
                }
                else
                {
                    html += '<div class="messages__item messages__item--visitor">' + item.message + '</div>'
                }
            });

            const chatmessage = document.querySelector('.chatbox__messages');
            chatmessage.innerHTML = html;
        });
    }

    return(
        <div className="chatbox">
            <div className={toggleClass ? "chatbox__support chatbox--active" : "chatbox__support chatbox--hidden"}>
                <div className="chatbox__header">
                    <div className="chatbox__image--header">
                        <img src="https://img.icons8.com/color/48/000000/circled-user-female-skin-type-5--v1.png" alt="pro-pic" />
                    </div>
                    <div className="chatbox__content--header">
                        <h4 className="chatbox__heading--header">Chat support</h4>
                        <p className="chatbox__description--header">Hi. My name is Edith. How can I help you?</p>
                    </div>
                </div>
                <div className="chatbox__messages">
                    <div></div>
                </div>
                <div className="chatbox__footer">
                    <input className="chatInput" type="text" onChange={handleInput} placeholder="Write a message..." />
                    <button className="chatbox__send--footer send__button" onClick={sendMessage}>Send</button>
                </div>
            </div>
            <div className="chatbox__button">
                <button onClick={() => setToggleClass(!toggleClass)}><img src={icon} alt=""/></button>
            </div>
        </div>
    );
}

export default ChatBot;