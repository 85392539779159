import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDMKGYV2dy6J3fYwgvgehVa8SwDn7F43DY",
  authDomain: "portfolio-website-3954a.firebaseapp.com",
  projectId: "portfolio-website-3954a",
  storageBucket: "portfolio-website-3954a.appspot.com",
  messagingSenderId: "403925294198",
  appId: "1:403925294198:web:92a8bf6ce801ac93999357",
  measurementId: "G-6QCYRKQJNM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);