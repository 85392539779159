import React from "react";
import image from "../images/myPic2.png";
import resume from "../res/Resume.pdf";

function About(){
    return(
        // <!-- Creating About Me Layout in HTML -->
        <section className="about" id="about">
            <div className="heading">
                {/* <!-- <p>Get to Know me</p> --> */}
                <h2>About Me</h2>
            </div>
            <div className="content">
                <div className="w50">
                    <img src={image} alt="" className="proPic" />
                </div>
                <div className="contentBx">
                    <h3>Who am I?</h3>
                    <h2>I am <b>Saurabh Mishra</b>, a Data Science and Machine Learning Engineer.</h2>
                    <p>I enjoy reading blogs and articles relating to new breakthroughs in the field of data science and computing. I am an active data science student currently persuing B.Tech in Data Science from SET, Jain University. I have learned data analysis using different machine learning tools in my course as well as from other online channels. Also, having a sharp eye for data evaluation helps me prioritize tasks, iterate fast and deliver faster.</p><br />
                    <p>Although I am a data major student, I have always had a very deep curiosity in full stack development. Thus, I have done some side projects in web and android, one example of which is this very website(building, hosting and ssl security).</p>
                </div>
            </div>
            <div className="heading bottom">
                <div>
                    <a href={resume} className="btn round" target="_blank" rel="noreferrer">Download CV</a>
                </div>
                <div className="bt">
                    <b>Name:</b> Saurabh Mishra<br />
                    <b>Age:</b> 22
                </div>
                <div className="bt">
                    <b>Email:</b> mishraji.ds@gmail.com<br />
                    <b>Location:</b> INDIA
                </div>
            </div>
        </section>
    );
}

export default About;