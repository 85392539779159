import './App.css';
import './components/ext.js'
import Header from './components/Header';
import Banner from './components/Banner';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Blog from './components/Blog';
import Project from './components/Project';
import ChatBot from './components/ChatBot';

function App() {
  return (
    <div className="App">
      <Header />
      <Banner />
      <About />
      <Project />
      <Blog />
      <Contact />
      <ChatBot />
      <Footer />
    </div>
  );
}

export default App;
