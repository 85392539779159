import React from "react";

function Contact(){
    return(
        // <!-- Creating Contact Layout in HTML -->
        <section className="contact" id="contact">
            <div className="heading white">
                {/* <!-- <p>Feel free to contact me anytime</p> --> */}
                <h2>Get in Touch</h2>
            </div>
            <div className="contact content">
                <div className="contactInfo">
                    <h3>Contact Info</h3>
                    <p>Feel free to contact me!</p>
                    <div className="contactInfoBx">
                        <div className="box">
                            <div className="icon">
                                <i className="fa fa-map-marker"></i>
                            </div>
                            <div className="text">
                                <h3>Address</h3>
                                <p>Jain (Deemed-to-be) University,<br />Kanakpura Rd<br />Bangalore, Karnataka,<br />India</p>
                            </div>
                        </div>
                        <div className="box">
                            <div className="icon">
                                <i className="fa fa-phone"></i>
                            </div>
                            <div className="text">
                                <h3>Phone no :</h3>
                                <p>+91 8050976759<br />+977 9814877469</p>
                            </div>
                        </div>
                        <div className="box">
                            <div className="icon">
                                <i className="fa fa-envelope-o"></i>
                            </div>
                            <div className="text">
                                <h3>Email Address</h3>
                                <p>mishraji.ds@gmail.com<br />contact@saurabhmishra.com.np</p>
                            </div>
                        </div>
                        <br />
                        <h3>Social Connect</h3>
                        <div className="boxSocial">
                            <div className="icon">
                                <a href="https://www.facebook.com/geekMISHRAJI/" target="_blank" rel="noreferrer">
                                    <i className="fa fa-facebook-square"></i>
                                </a>
                            </div>
                            <div className="icon">
                                <a href="https://www.linkedin.com/in/geekMISHRAJI/" target="_blank" rel="noreferrer">
                                    <i className="fa fa-linkedin-square"></i>
                                </a>
                            </div>
                            <div className="icon">
                                <a href="https://www.instagram.com/itsmishrajee/" target="_blank" rel="noreferrer">
                                    <i className="fa fa-instagram"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="formBx">
                    <form name="contact" method="post">
                        <input type="hidden" name="form-name" value="contact" />
                        <h3>Message Me</h3>
                        <input type="text" placeholder="Full Name" name="name" />
                        <input type="email" placeholder="Email" name="email" />
                        <textarea name="message" rows="6" id="" placeholder="Your Message"></textarea>
                        <div className="submit">
                            <div className="btn"><input type="submit" value="Send Message" name="" /></div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}

export default Contact;